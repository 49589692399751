<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{selectedValues.toString()}}
    </div>
    <div v-else>
      <label
        v-for='selectable in field.selectable_values'
        :key='`checkbox-selectableValue-${field.custom_field_name}-${selectable}`'
        class='block'>
        <input type='checkbox'
          class='form-checkbox'
          v-model='selectedValues'
          @change='handleUpdate'
          :value='selectable'>
        {{selectable}}
      </label>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'


export default {
  name: 'CompanyFieldCheckbox',
  props: ['field', 'isViewMode'],
  data () {
    return {
      selectedValues: [],
    }
  },
  watch: {
    'field.value_json': {
      handler: function (newVal) {
        if (newVal) {
          this.selectedValues = newVal
        } else {
          this.selectedValues = []
        }
      },
      immediate: true
    },
  },
  methods: {
    handleUpdate () {
      let updated = cloneDeep(this.field)
      updated.value = this.selectedValues.join(',')
      updated.value_json = this.selectedValues
      this.$emit('update-value', updated)
    },
  },
}
</script>
