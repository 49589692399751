<template>
  <div>
    <div v-if='isViewMode'>
      {{formattedNumber }}
    </div>
    <input
      v-model="fieldValue"
      type="text"
      class="form-input w-1/4 text-right"
      @blur="handleUpdate"
      @input="formatWithThousandSeparator"
    />
  </div>
</template>

<script>
import numbro from 'numbro'

export default {
  name: 'CompanyFieldNumber',
  props: ['field', 'isViewMode'],
  data () {
    return {
      fieldValue: 0,
    }
  },
  watch: {
    computedString: {
      handler: function (newVal) {
        if (newVal) {
          this.fieldValue = Number(this.field.value)
        }
      },
      immediate: true,
    }
  },
  computed: {
    formattedNumber () {
      return (this.field.value) ? numbro(this.field.value).format({ thousandSeparated: true }) : ''
    },
    computedString() {
      return `${this.field.id}-${this.field.custom_field_template_id}-${this.field.company_id}`
    },
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', {...this.field, value: this.fieldValue})
    },
    formatWithThousandSeparator(event) {
      let value = event.target.value.replace(/\D/g, '')
      value = Number(value).toLocaleString('en-US')
      this.fieldValue = value
    },
  },
}
</script>
