<template>
  <div
    slot='content'
    class='mt-8'>
      <div>
      <div class='flex flex-row justify-between items-end px-2 lg:px-8'>
        <div>
          <h1 class='text-xl font-semibold uppercase'>
            {{timesheetDetails.case_name}}
          </h1>
          <h3 class='text-xs text-gray-800'>
            {{timesheetDetails.case_id}}
          </h3>
          <h3 class='text-sm text-gray-800'>
            {{subTitle}}
            <span v-if='counterpartyExist'>
              <span class='inline-block px-2 text-gray-600'>v.</span>
              {{timesheetDetails.counterparty}}
            </span>
          </h3>
        </div>
      </div>
    </div>
    <button
      @click='exportExcel'
      class='block border mt-8 ml-8 px-4 py-2 lg:px-8 text-gray-700 text-sm font-semibold rounded-md hover:border-blue-300 hover:shadow-md'>
      {{ $t('excelDownload') }}
    </button>
    <div class='mt-8 px-2 lg:px-8'>
      <table
        id='caseTimesheet'
        class='table-fixed'>
        <thead>
          <tr class='whitespace-no-wrap border-b border-gray-200 text-sm uppercase'>
            <th class='py-2 px-3 text-left'>{{ $t('team') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('lawyer') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('workType') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('workDate') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('description') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('workHours') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('timesheetHourlyRate') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('cost') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('associateUsedAmount') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='detail in timesheetDetails.timesheet_details'
              :key='`${detail.id}-${detail.casenum}-${detail.work_date}-${detail.work_type_name}`'
              class='border-b border-gray-200 text-xs'>
            <td class='py-2 px-3 whitespace-no-wrap'>{{detail.user_team}}</td>
            <td class='py-2 px-3'>{{detail.user_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{detail.work_type_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{detail.work_date}}</td>
            <td class='py-2 px-3'>{{detail.description}}</td>
            <td class='py-2 px-3 text-right'>{{detail.work_hours}}</td>
            <td class='py-2 px-3 text-right'>{{formatNumber(detail.hourly_rate)}}</td>
            <td class='py-2 px-3 text-right'>{{formatNumber(detail.associate_amount)}}</td>
            <td class='py-2 px-3 text-right'>{{formatNumber(associateUsedAmount(detail))}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class='border-b border-gray-200 text-xs'>
            <td class='py-2 px-3 whitespace-no-wrap'></td>
            <td class='py-2 px-3 whitespace-no-wrap'></td>
            <td class='py-2 px-3 whitespace-no-wrap'></td>
            <td class='py-2 px-3 whitespace-no-wrap'></td>
            <td class='py-2 px-3'></td>
            <td class='py-2 px-3 text-right'></td>
            <td class='py-2 px-3 text-right'></td>
            <td class='py-2 px-3 text-right'>{{ formatNumber(totalAssociateAmount) }}</td>
            <td class='py-2 px-3 text-right'>{{ formatNumber(totalAssociateUsedAmount) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import numbro from 'numbro'
import { mapState } from 'vuex'
import * as XLSX    from 'xlsx-js-style'

export default {
  name: 'TimeSheetsDetails',
  computed: {
    ...mapState('lawyerPerformanceReports', [
      'timesheetDetails'
    ]),
    totalAssociateAmount () {
      return this.timesheetDetails.timesheet_details?.reduce((sum, detail) => {
        return sum + parseFloat(detail.associate_amount)
      }, 0)
    },
    totalAssociateUsedAmount () {
      return this.timesheetDetails.timesheet_details?.reduce((sum, detail) => {
        return sum + Math.round(parseFloat(detail.associate_amount) * parseFloat(this.timesheetDetails.share_ratio))
      }, 0)
    },
    counterpartyExist () {
      return this.timesheetDetails.counterparty
    },
    subTitle () {
      return this.timesheetDetails.entity_names ? this.timesheetDetails.entity_names : ''
    },
  },
  methods: {
    exportExcel () {
      let lawyer_name = this.timesheetDetails ? this.timesheetDetails.case_name : ''
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('caseTimesheet')) // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new() // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, '타임시트 상세내역')  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `${lawyer_name}_타임시트 상세내역.xlsx`) // 엑셀파일 만듬
    },
    formatNumber (num) {
      return numbro(num).format({mantissa: 0, thousandSeparated: true})
    },
    associateUsedAmount (detail) {
      return Math.round(detail.associate_amount * parseFloat(this.timesheetDetails.share_ratio))
    },
  },
}
</script>
