import Vue       from 'vue'
import Vuex      from 'vuex'
import reportApi from '@/api/v1/case_performance_reports'

import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  invoices: [],
  cases: [],
})

const getters = {
  getField,
}

const actions = {
  getInvoicesWithoutRatio ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getInvoicesWithoutRatio(params).then(resp => {
        commit('setInvoicesWithoutRatio', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getCasesWithoutInvoice ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getCasesWithoutInvoice(params).then(resp => {
        commit('setCasesWithoutInvoice', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  postDepositToInvoice ({ dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.postDepositToInvoice(params).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setInvoicesWithoutRatio (state, fromApi) {
    state.invoices = fromApi
  },
  setCasesWithoutInvoice (state, fromApi) {
    state.cases = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
