import base from './base'

const getMagams = () => base
  .get('/magams/').then(resp => resp.data)

const getMagamPerformances = (params) => base
  .get('/magam_performances/magam_histories', { params: params }).then(resp => resp.data)

const createMagamPerformances = (params) => base
  .patch('magam_performances/create_magam_performances', {from_date: params.from_date, to_date: params.to_date}).then(resp => resp.data)

const createChangePerformances = (params, contentType='application/json') => base
  .patch('/change_performances/create_diff_performances', {investment_id: params.investment_id, invoice_id: params.invoice_id}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvoiceChangePerformances = (params, contentType='application/json') => base
  .patch('/change_performances/delete_invoice_diff_performances', {investment_id: params.investment_id, invoice_id: params.invoice_id}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteDepositChangePerformances = (params, contentType='application/json') => base
  .patch('/change_performances/delete_deposit_diff_performances', {deposit_id: params.deposit_id}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const getTimesheetsMagamPerformances = (params) => base
  .get('/magam_performances/timesheets_magam_histories', { params: params }).then(resp => resp.data)

export default {
  getMagams: getMagams,
  getMagamPerformances: getMagamPerformances,
  createMagamPerformances: createMagamPerformances,
  createChangePerformances: createChangePerformances,
  deleteInvoiceChangePerformances: deleteInvoiceChangePerformances,
  deleteDepositChangePerformances: deleteDepositChangePerformances,
  getTimesheetsMagamPerformances: getTimesheetsMagamPerformances,
}
