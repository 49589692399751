<template>
  <div>
    <portal to='sidepanel-header'>
      <h1 class='text-2xl font-semibold px-8 py-8'>{{companyFromId ? companyFromId.company_name : ''}}</h1>
      <h2 class='text-sm px-8'>{{companyFromId && companyFromId.english_name ? companyFromId.english_name : ''}}</h2>
    </portal>
    <div v-if='companyFromId'
      class='tabs-container border-b pl-2 md:pl-4 lg:pl-8 flex flex-row justify-start my-8 text-sm text-gray-600 overflow-x-auto'>
      <div v-for='tab in tabs'
        :key='`tab-${tab.name}`'
        class='tab' 
        :class='selectedStyle(tab.name)'
        @click='setActiveTab(tab.name)'>
        {{ tab.label }}
      </div>
    </div>
    <div 
      v-if='companyFromId'
      class='px-8'>
      <component :is='activeTabComponent' class='mb-8' />    
    </div>
  </div>
</template>

<script>
import CompanyDrilldownEntities from '@/components/CompanyDrilldownEntities.vue'
import CompanyDrilldownCases from '@/components/CompanyDrilldownCases.vue'
import CompanyDrilldownInvoices from '@/components/CompanyDrilldownInvoices.vue'
import CompanyDrilldownMetadata from '@/components/CompanyDrilldownMetadata.vue'
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'

export default {
  name: 'CompanyDrilldown',
  components: {
    CompanyDrilldownEntities,
    CompanyDrilldownCases,
    CompanyDrilldownInvoices,
    CompanyDrilldownMetadata,
  },
  props: {
  },
  data () {
    return {
      companyEntities: [],
      companyCases: [],
      invoicesByCompany: [],
      companyFieldValues: [],
      activeTab: 'metadata',
    }
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
      'companies',
    ]),
    tabs () {
      return [
        {
          name: 'metadata',
          label: 'Metadata',
          component: 'CompanyDrilldownMetadata',
        },
        {
          name: 'cases',
          label: 'Cases',
          component: 'CompanyDrilldownCases',
        },
        {
          name: 'invoices',
          label: 'Invoices',
          component: 'CompanyDrilldownInvoices',
        },
        {
          name: 'entities',
          label: 'Entities',
          component: 'CompanyDrilldownEntities',
        },
      ]
    },
    companyFromId () {
      return this.companies.find(company => company.id === this.drilldownCompanyId)
    },
    activeTabComponent () {
      return this.tabs.find(tab => tab.name === this.activeTab).component
    },
  },
  methods: {
    ...mapActions('companies', [
      'getCompanyCases',
      'getCompanyEntities',
      'getCompanyFieldValues',
      'resetDrilldownCompany',
    ]),
    ...mapActions('invoices', [
      'getInvoicesByCompany',
    ]),
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
    selectedStyle (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
    setActiveTab (tab) {
      this.activeTab = tab
      // update router query params for copying and pasting
      // if (this.$route.query.tab !== tab) {
      //   let newQueryParams = cloneDeep(this.$route.query)
      //   newQueryParams['tab'] = tab
      //   this.$router.replace({ query: newQueryParams })
      // }
    },
  },
  beforeDestroy () {
    this.resetDrilldownCompany()
  }
}
</script>

<style lang='scss' scoped>

  button:disabled,
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .tab {
    @apply px-4 pb-2 pt-4 uppercase tracking-wide cursor-pointer whitespace-no-wrap;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }

  @media (min-width: 800px) {
    .tab {
      @apply px-4;
    }
  }

  @media (min-width: 1000px) {
    .tab {
      @apply px-6;
    }
  }
</style>
