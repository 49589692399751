<template>
  <div class="loading">
    <div class="loading-1"></div>
    <div class="loading-2"></div>
    <div class="loading-3"></div>
    <div class="loading-4"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator'
}
</script>

<style lang='scss'>
.loading {
  position: absolute;
  float: left;
  top: calc(50% - 50px);
  left: calc(50% - 70px);
  height: 140px;
  padding: 24px;
  width: auto;
  background-color: #333;
  text-align: center;
  z-index: 10;
}

@keyframes loading {
  0% { border-top-color: #d13632; }
  11% { border-top-color: #e2571e; }
  22% { border-top-color: #E09128; }
  33% { border-top-color: #ffe400; }
  44% { border-top-color: #7dd132; }
  50% { height: 100px; margin-top: 0px; }
  55% { border-top-color: #32D152; }
  66% { border-top-color: #32d15b; }
  77% { border-top-color: #32bcd1; }
  88% { border-top-color: #323ad1; }
  99% { border-top-color: #cb32d1; }
  100% { border-top-color: #cb32d1; }
}
/*@-moz-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@-o-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}*/
@mixin inner() {
  height: 20px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  margin-top: 80px;
  margin-left: 5px;
  margin-right: 5px;
  -webkit-animation: loading 1.2s infinite;
  -moz-animation: loading 1.2s infinite;
  -o-animation: loading 1.2s infinite;
  animation: loading 1.2s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top: 0px solid #333;
}
@mixin loading() {
  @for $i from 1 through 10 {
    .loading-#{$i} { @include inner(); -webkit-animation-delay: calc($i/4)+s; animation-delay: calc($i/4)+s; }
  }
}
.loading { @include loading(); }
</style>
