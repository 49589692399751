<template>
  <div>
    <h1 class='font-semibold uppercase'>
      invoices 
      <span class='text-normal'>({{ invoicesByCompany.length }})</span>
    </h1>
    <table v-if='invoicesByCompany.length'
      class='mt-2 text-sm w-full'>
      <thead>
        <tr class='border-b'>
          <th class='p-1'>Invoice Date</th>
          <th class='p-1'>Invoice Title</th>
          <th class='p-1 text-right'>Total Amount</th>
        </tr>
      </thead>
      <tr class='border-b border-gray-100'
        v-for='invoice in invoicesByCompany' 
        :key='`invoice-${invoice.id}`'>
        <td class='p-1'>{{ invoice.invoice_date }}</td>
        <td class='p-1'>{{ invoice.invoice_title }}</td>
        <td class='p-1 text-right'>{{ formatNumber(invoice.total_amount) }}</td>
      </tr>
    </table>
    <div v-else
      class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
      <p>No invoices found</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'

export default {
  name: 'CompanyDrilldownInvoices',
  data () {
    return {
      invoicesByCompany: [],
    }
  },
  watch: {
    drilldownCompanyId: {
      handler: function (newVal) {
        if (newVal) {
          this.getInvoicesByCompany(newVal).then(resp => {
            this.invoicesByCompany = resp
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
    ]),
  },
  methods: {
    ...mapActions('invoices', [
      'getInvoicesByCompany',
    ]),
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
  },
}
</script>

<style lang='scss' scoped>

  button:disabled,
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

</style>
