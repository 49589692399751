<template>
  <div class=''>
    <h1 class='font-semibold uppercase'>
      entities 
      <span class='text-normal'>({{ companyEntities.length }})</span>
    </h1>
    <table 
      v-if='companyEntities.length'
      class='mt-2 text-sm w-full'>
      <thead>
        <tr class='border-b'>
          <th class='p-1'>ID</th>
          <th class='p-1'>Entity Name</th>
          <th class='p-1'>Entity Type</th>
          <th class='p-1'>Company Type</th>
          <th class='p-1'>Business License Number</th>
        </tr>
      </thead>
      <tr class='border-b border-gray-100' 
        v-for='entity in companyEntities' 
        :key='`entity-${entity.id}`'>
        <td class='p-1'>{{ entity.id }}</td>
        <td class='p-1'>{{ entity.entity_name }}</td>
        <td class='p-1'>{{ entity.entity_type }}</td>
        <td class='p-1'>{{ entity.company_type }}</td>
        <td class='p-1'>{{ entity.business_license_number }}</td>
      </tr>
    </table>
    <div v-else
      class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
      <p>No entities found</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'

export default {
  name: 'CompanyDrilldownEntities',
  components: {
  },
  props: {
  },
  data () {
    return {
      companyEntities: [],
    }
  },
  watch: {
    drilldownCompanyId: {
      handler: function (newVal) {
        if (newVal) {
          this.getCompanyEntities(newVal).then(resp => {
            this.companyEntities = resp
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
    ]),
  },
  methods: {
    ...mapActions('companies', [
      'getCompanyEntities',
    ]),
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
  },
}
</script>

<style lang='scss' scoped>

  button:disabled,
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

</style>
