<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{ selectedValues.toString() }}
    </div>
    <div v-else>
      <v-select
        v-model='selectedValues'
        label='value'
        :options='field.selectable_values'
        :close-on-select='false'
        multiple
        @input='handleUpdate'
        ></v-select>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CompanyFieldMultiSelect',
  props: ['field', 'isViewMode'],
  data () {
    return {
      selectedValues: [],
    }
  },
  watch: {
    'field.value_json': {
      handler: function (newVal) {
        if (newVal) {
          this.selectedValues = newVal
        } else {
          this.selectedValues = []
        }
      },
      immediate: true
    },
  },
  methods: {
    handleUpdate () {
      let updated = cloneDeep(this.field)
      updated.value = this.selectedValues.join(',')
      updated.value_json = this.selectedValues
      this.$emit('update-value', updated)
    }
  }
}
</script>
