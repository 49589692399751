<template>
  <div>
    <div v-if='isViewMode'>
      {{localFieldValue}}
    </div>
    <input
      v-else
      type='text'
      v-model='localFieldValue'
      @blur='handleUpdate'
      class='form-input w-full'/>
  </div>
</template>

<script>

export default {
  name: 'CompanyFieldText',
  props: [ 'field', 'isViewMode' ],
  data () {
    return {
      localFieldValue: ''
    }
  },
  watch: {
    computedString: {
      handler: function (newVal) {
        if (newVal) {
          this.localFieldValue = this.field.value
        }
      },
      immediate: true,
    },
  },
  computed: {
    computedString() {
      return `${this.field.id}-${this.field.custom_field_template_id}-${this.field.company_id}`
    },
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', {...this.field, value: this.localFieldValue})
    }
  },
}
</script>
