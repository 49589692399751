<template>
  <div class='mb-8'>
    <div 
      v-for='companyFieldValue in companyFieldValues' 
      :key='`field-val-${companyFieldValue.custom_field_name}`'>
      <div class='mt-6 text-sm rounded-md p-2'>
        <h4 class='font-medium mb-2'>{{ companyFieldValue.custom_field_name }}</h4>
        <component
          :is='inputType(companyFieldValue.field_type)'
          :field='companyFieldValue'
          :is-view-mode='false'
          @update-value='(field) => handleUpdate(companyFieldValue.custom_field_template_id, field)' />
      </div>
    </div>
    <portal to='sidepanel-footer'>
      <div class='w-full bg-gray-100 pl-8 pr-8 py-3 text-sm shadow-lg'>
        <button
          class='inline-block uppercase py-3 px-12 text-white rounded-md font-bold border border-transparent bg-gray-800 hover:bg-gray-900'
          :disabled='savingData'
          @click='saveCompanyFieldValues'>
          Save
        </button>
        <button
          class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
          @click='cancelCompanyFieldValues'>
          Cancel
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'
import CompanyFieldCheckbox    from '@/components/CompanyFieldCheckbox.vue'
import CompanyFieldMultiSelect from '@/components/CompanyFieldMultiSelect.vue'
import CompanyFieldDate        from '@/components/CompanyFieldDate.vue'
import CompanyFieldDropdown    from '@/components/CompanyFieldDropdown.vue'
import CompanyFieldNumber      from '@/components/CompanyFieldNumber.vue'
import CompanyFieldParagraph   from '@/components/CompanyFieldParagraph.vue'
import CompanyFieldText        from '@/components/CompanyFieldText.vue'
import cloneDeep              from 'lodash/cloneDeep'

export default {
  name: 'CompanyDrilldownMetadata',
  components: {
    CompanyFieldCheckbox,
    CompanyFieldMultiSelect,
    CompanyFieldDate,
    CompanyFieldDropdown,
    CompanyFieldNumber,
    CompanyFieldParagraph,
    CompanyFieldText,
  },
  props: {
  },
  data () {
    return {
      companyFieldValues: [],
      savingData: false,
    }
  },
  watch: {
    drilldownCompanyId: {
      handler: function (newVal) {
        if (newVal) {
          this.getCompanyFieldValues(newVal).then(resp => {
            this.companyFieldValues = resp.map(fieldValue => ({
              ...fieldValue,
              company_id: newVal,
            }))
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
    ]),
  },
  methods: {
    ...mapActions('companies', [
      'getCompanyFieldValues',
      'setCompanyFieldValues',
    ]),
    saveCompanyFieldValues () {
      this.savingData = true
      this.setCompanyFieldValues(this.companyFieldValues).then(resp => {
        console.log(resp)
        this.savingData = false
      })
    },
    cancelCompanyFieldValues () {
      // this.companyFieldValues = []
    },
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
    inputType(fieldType) {
      const unsupportedFields = ['user', 'contact', 'file', 'table', 'fund', 'date-range']
      if (unsupportedFields.includes(fieldType)) {
        return 'div'
      }
      return `company-field-${fieldType}`
    },
    handleUpdate(id, field) {
      const fieldValueIndex = this.companyFieldValues.findIndex(value => value.custom_field_template_id === id)
      if (fieldValueIndex !== -1) {
        this.companyFieldValues.splice(fieldValueIndex, 1, cloneDeep(field))
      }
    },
  },
}
</script>

<style lang='scss' scoped>

  button:disabled,
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

</style>
