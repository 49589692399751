import Vue       from 'vue'
import Vuex      from 'vuex'
import reportApi from '@/api/v1/lawyer_performance_reports'

import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  drilldownCasesInfo: {
    userName: '',
    caseType: '',
    cases: []
  },
  drilldownLawyerTimeSheets: {},
  summaries: [],
  timesheetSummaries: [],
  teamSummaries: [],
  caseCountSummaries: [],
  exclusiveCosts: [],
  timesheetDetails: {},
  magamPerformanceDetails: [],
})

const getters = {
  getField,
}

const actions = {
  getCases ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getCases(params).then(resp => {
        commit('setDrilldownCases', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getOverview ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getOverview(params).then(resp => {
        commit('setSummaries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getTimesheets ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getTimesheets(params).then(resp => {
        commit('setTimesheetSummaries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getTeamOverview ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getTeamOverview(params).then(resp => {
        commit('setTeamSummaries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getLawyerCaseCount ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getLawyerCaseCount(params).then(resp => {
        commit('setCaseCount', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getLawyerTimesheets ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getLawyerTimesheets(params).then(resp => {
        commit('setDrilldownLawyerTimeSheets', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getExclusiveCosts ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getExclusiveCosts(params).then(resp => {
        commit('setExclusiveCosts', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getMagamPerformanceDetails ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      if (params.page === 1) {
        commit('resetMgamPerformanceDetails')
        dispatch('activateLoading', null, { root : true })
      }

      reportApi.getMagamPerformanceDetails(params).then(resp => {
        commit('setMagamPerformanceDetails', resp.data)
        if (resp.meta.pages == params.page) {
          dispatch('deactiveLoading', null, { root : true })
          resolve()
        } else {
          params.page = params.page + 1
          dispatch('getMagamPerformanceDetails', params, { root : true })
        }
      })
    })
  },
  getDiffPerformanceDetails ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      reportApi.getDiffPerformanceDetails(params).then(resp => {
        commit('setMagamPerformanceDetails', resp.data)
        if (resp.meta.pages == params.page) {
          dispatch('deactiveLoading', null, { root : true })
          resolve()
        } else {
          params.page = params.page + 1
          dispatch('getDiffPerformanceDetails', params, { root : true })
        }
      })
    })
  }
}

const mutations = {
  setDrilldownCases (state, fromApi) {
    state.drilldownCasesInfo.cases = fromApi
  },
  setDrilldownLawyerTimeSheets (state, fromApi) {
    state.drilldownLawyerTimeSheets = fromApi
  },
  setDrilldownUserName (state, name) {
    state.drilldownCasesInfo.userName = name
  },
  setDrilldownCaseType (state, caseType) {
    state.drilldownCasesInfo.caseType = caseType
  },
  setSummaries (state, fromApi) {
    state.summaries = fromApi
  },
  setTimesheetSummaries (state, fromApi) {
    state.timesheetSummaries = fromApi.data
  },
  setTeamSummaries (state, fromApi) {
    state.teamSummaries = fromApi
  },
  setCaseCount (state, fromApi) {
    state.caseCountSummaries = fromApi
  },
  setTimesheetSummaryShowDetails (state, { summary, isVisible }) {
    let found = state.timesheetSummaries.find(timesheet => timesheet.user_id === summary.user_id)
    found.show_details = isVisible
  },
  setTimesheetDetails (state, fromApi) {
    state.timesheetDetails = fromApi
  },
  setExclusiveCosts (state, fromApi) {
    state.exclusiveCosts = fromApi
  },
  resetMgamPerformanceDetails (state) {
    state.magamPerformanceDetails = []
  },
  setMagamPerformanceDetails (state, fromApi) {
    state.magamPerformanceDetails = state.magamPerformanceDetails.concat(fromApi)
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
