<template>
  <div v-if='isViewMode'
     class='whitespace-pre-line'>
     {{fieldValue}}
    </div>
  <textarea v-else
    v-model='fieldValue'
    @blur='handleUpdate'
    rows='3'
    class='form-textarea'></textarea>
</template>

<script>

export default {
  name: 'CompanyFieldParagraph',
  props: ['field', 'isViewMode'],
  data() {
    return {
      fieldValue: '',
    }
  },
  watch: {
    computedString: {
      handler: function (newVal) {
        if (newVal) {
          this.fieldValue = this.field.value
        }
      },
      immediate: true,
    },
  },
  computed: {
    computedString() {
      return `${this.field.id}-${this.field.custom_field_template_id}-${this.field.company_id}`
    },
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', { ...this.field, value: this.fieldValue })
    }
  }
}
</script>
