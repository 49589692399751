import Vue       from 'vue'
import Vuex      from 'vuex'
import api from '@/api/v1/case_infos'

Vue.use(Vuex)

const state = () => ({
  caseJurisdictions: [],
})

const getters = {
  jurisdictionByCaseType: state => caseType => {
    let jurisdictions = state.caseJurisdictions.filter(juris => juris.case_type === caseType)
    return jurisdictions.map(juris => juris.jurisdiction_name)
  },
}

const actions = {
  getCaseJurisdictions ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      api.getCaseJurisdictions(params).then(resp => {
        commit('setCaseJurisdictions', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setCaseJurisdictions (state, fromApi) {
    state.caseJurisdictions = fromApi
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
