import base from './base'

const getCompanyFieldValuesForCompany = (companyId, fieldType = 'all') => base
  .get(`/company_field_values/`, {
    params: {
      company_id: companyId,
      field_type: fieldType,
    }
  }).then(resp => resp.data)

const setCompanyFieldValues = (fieldValues) => base
  .patch(`/company_field_values/set_updated_values`, {
    company_field_values: fieldValues
  }).then(resp => resp.data)

export default {
  getCompanyFieldValuesForCompany: getCompanyFieldValuesForCompany,
  setCompanyFieldValues: setCompanyFieldValues,
}
