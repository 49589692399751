<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{dateString}}
    </div>
    <div v-else>
      <date-picker
        v-model='localFieldValue'
        @change='handleUpdate'
        type='date'
        format='YYYY-MM-DD'
        value-type='format'
        lang=ko
        :placeholder="$t('pickDay')">
      </date-picker>
      <button 
        v-if='showSetToday' 
        class='ml-2 text-sm font-bold' 
        @click='setPickerDate'>
        <calendar-icon class='mb-1 mr-1 inline-block'/> {{ $t('useTodaysDate') }}
      </button>
    </div>
  </div>
</template>

<script>
import { CalendarIcon } from '@vue-hero-icons/outline'
import dayjs            from 'dayjs'
import DatePicker       from 'vue2-datepicker'
import                  'vue2-datepicker/index.css'
import ko               from 'vue2-datepicker/locale/ko'

export default {
  name: 'CompanyFieldDate',
  props: ['field', 'isViewMode'],
  components: {
    CalendarIcon,
    DatePicker,
  },
  data () {
    return {
      calendarEvent: {},
      showAddCalendarEvent: false,
      localFieldValue: '',
    }
  },
  watch: {
    computedString: {
      handler: function (newVal) {
        if (newVal) {
          this.localFieldValue = this.field.value
        }
      },
      immediate: true,
    }
  },
  computed: {
    dateString () {
      return (this.isValidDate) ? dayjs(this.localFieldValue).format('YYYY년 MM월 DD일') : ''
    },
    showSetToday () {
      return this.localFieldValue !== this.getTodayDate()
    },
    isValidDate () {
      return dayjs(this.localFieldValue).isValid()
    },
    computedString() {
      return `${this.field.id}-${this.field.custom_field_template_id}-${this.field.company_id}`
    },
  },
  methods: {
    handleUpdate() {
      this.$emit('update-value', {...this.field, value: this.localFieldValue})
    },
    getTodayDate() {
      return dayjs().format('YYYY-MM-DD')
    },
    setPickerDate() {
      this.localFieldValue = this.getTodayDate()
      this.handleUpdate()
    }
  },
}
</script>
