<template>
  <div>
    <div v-if='isViewMode'>
      {{selectableValue}}
    </div>
    <select v-else
      v-model='selectableValue'
      @change='handleUpdate'
      class='form-select w-64'>
      <option v-for='selectable in field.selectable_values' :key='`dropdown-selectableValue-${field.custom_field_name}-${selectable}`'>
        {{selectable}}
      </option>
    </select>
    <button v-if='canClear'
      class='inline-flex items-center px-3'
      @click='clearField'>
      <x-circle-icon class='inline-bock text-red-900 opacity-75' />
    </button>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { XCircleIcon } from '@vue-hero-icons/outline'

export default {
  name: 'CompanyFieldDropdown',
  props: ['field', 'isViewMode'],
  components: {
    XCircleIcon,
  },
  data () {
    return {
      selectableValue: ''
    }
  },
  watch: {
    'field.value': {
      handler: function (newVal) {
        this.selectableValue = newVal
      },
      immediate: true,
    }
  },
  computed: {
    canClear() {
      return !this.isViewMode && this.field.value !== null && this.field.value !== ''
    },
  },
  methods: {
    handleUpdate () {
      let updated = cloneDeep(this.field)
      updated.value = this.selectableValue
      this.$emit('update-value', updated)
    },
    clearField() {
      let updated = cloneDeep(this.field)
      updated.value = null
      this.$emit('update-value', updated)
    },
  }
}
</script>
