import Vue       from 'vue'
import Vuex      from 'vuex'
import codeListsApi from '@/api/v1/code_lists'

import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  codeLists: {},
})

const getters = {
  getField,
}

const actions = {
  getCodeLists ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      codeListsApi.getCodeLists(params).then(resp => {
        commit('setCodeLists', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setCodeLists (state, fromApi) {
    state.codeLists = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
