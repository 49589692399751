<template>
  <div>
    <h1 class='font-semibold uppercase'>
      cases 
      <span class='text-normal'>({{ companyCases.length }})</span>
    </h1>
    <table v-if='companyCases.length'
      class='mt-2 text-sm w-full'>
      <thead>
        <tr class='border-b'>
          <th class='p-1'>ID</th>
          <th class='p-1'>Name</th>
          <th class='p-1'>Clients Name</th>
          <th class='p-1'>Counterparty</th>
          <th class='p-1'>Archived</th>
        </tr>
      </thead>
      <tr class='border-b border-gray-100'
        v-for='companyCase in companyCases' 
        :key='`case-${companyCase.id}`'>
        <td class='p-1'>{{ companyCase.id }}</td>
        <td class='p-1'>{{ companyCase.investment_name }}</td>
        <td class='p-1'>{{ companyCase.clients_name }}</td>
        <td class='p-1'>{{ companyCase.counterparty }}</td>
        <td class='p-1'>{{ companyCase.archived }}</td>
      </tr>
    </table>
    <div v-else
      class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
      <p>No cases found</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'

export default {
  name: 'CompanyDrilldownCases',
  components: {
  },
  props: {
  },
  data () {
    return {
      companyCases: [],
    }
  },
  watch: {
    drilldownCompanyId: {
      handler: function (newVal) {
        if (newVal) {
          this.getCompanyCases(newVal).then(resp => {
            this.companyCases = resp
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
    ]),
  },
  methods: {
    ...mapActions('companies', [
      'getCompanyCases',
    ]),
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
  },
}
</script>

<style lang='scss' scoped>

  button:disabled,
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

</style>
