import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'

class InvoicesHelpers {
  static newInvoice () {
    return cloneDeep({
      id: 0,
      investment_id: 0,
      invoice_date: dayjs().format('YYYY-MM-DD'),
      invoice_type: '',
      external_url: '',
      total_amount: 0,
      total_tax: 0,
      status: '초안',
      use_calculated_total: true,
      include_menual_tax: false,
      commission_ratio: 0,
      payment_type: '',
      account_number: '',
      memo: '',
      deposit_status: '',
      issue_evidence: '',
      issue_tax_invoice: '',
      user_position: '',
    })
  }

  static selectableStatuses () {
    return cloneDeep([
      '초안',
      '비용청구',
      '청구요청',
      '청구완료',
      '발행완료',
    ])
  }

  static selectableDepositStatuses () {
    return cloneDeep([
      '미입금',
      '부분입금',
      '입금완료',
    ])
  }

  static selectableInvoiceTypes () {
    return cloneDeep([
      '착수금/기본보수',
      '성사금/추가보수',
      '고문료',
      '자문료',
      '타임차지',
      '기타보수',
      '소송비용',
      '기타비용',
      '상담료',
    ])
  }

  static selectableInvoicePaymentTypes () {
    return cloneDeep([
      '계좌입금',
      '현금',
      '카드',
      '내부보관',
    ])
  }

  static selectableIssueEvidences () {
    return cloneDeep([
      '현금영수증',
      '세금계산서',
    ])
  }

  static selectableIssueTaxInvoice () {
    return cloneDeep([
      '영수',
      '청구',
    ])
  }

  static selectableAccountNumbers () {
    return cloneDeep([
      {bank: '우리은행', depositor: '법무법인(유한) 대륙아주', account_number: '169-536290-13-102'},
      {bank: '우리은행', depositor: '법무법인(유한) 대륙아주', account_number: '169-536290-13-104'},
      {bank: '우리은행', depositor: '신청전담팀', account_number: '1005-403-102155'},
      {bank: '우리은행', depositor: '상담료', account_number: '1005-802-178250'},
      {bank: '우리은행(외화계좌)', depositor: '법무법인(유한) 대륙아주', account_number: '1081-500-394263'},
      {bank: '신한은행', depositor: '법무법인(유한) 대륙아주', account_number: '140-004-249773'},
      {bank: '하나은행', depositor: '법무법인(유한) 대륙아주', account_number: '102-910059-32804'},
      {bank: 'KB은행', depositor: '법무법인(유한) 대륙아주', account_number: '349401-04-062184'},
    ])
  }

}

export default InvoicesHelpers
